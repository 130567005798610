import './Counter.scss';

import cx from 'classnames';
import React from 'react';
import CountUp from 'react-countup';

const getAttrsByType = (type, value) => {
  const numValue = parseFloat(value) || 0;

  switch (type) {
    case 'percent':
      return {
        end: numValue,
        suffix: '%',
      };

    case 'money': {
      let end = numValue;
      let suffix = '';

      if (numValue >= 1000000000) {
        end = numValue / 1000000000;
        suffix = 'B';
      } else if (numValue >= 1000000) {
        end = numValue / 1000000;
        suffix = 'M';
      } else if (numValue >= 1000) {
        end = numValue / 1000;
        suffix = 'K';
      }

      return {
        end,
        prefix: '$',
        suffix,
      };
    }

    default:
      return {
        end: numValue,
      };
  }
};

const getEmptyVal = (type) => {
  switch (type) {
    case 'percent':
      return 'XX%';

    case 'money':
      return '$XXM';

    default:
      return 'XX';
  }
};

const Counter = ({ attrs, className, value, type }) => {
  const numValue = parseFloat(value) || 0;
  const typeAttrs = getAttrsByType(type, numValue);

  const rootCx = cx('counter', className);

  return (
    <div className={rootCx}>{numValue ? <CountUp duration={4} {...typeAttrs} {...attrs} /> : getEmptyVal(type)}</div>
  );
};

export default Counter;
